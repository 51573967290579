@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  color: #031124;
}

:root {
  font-family: "League Spartan", sans-serif;
}
